


































































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import api from "@/core/utils/api";
import { Seminar, SeminarElement, Submission } from "@/core/models";
import RephraseSubmission from "./RephraseSubmission.vue";
import ElementControls from "./ElementControls.vue";

@Component({ components: { RephraseSubmission, ElementControls } })
export default class RephraseElement extends Vue {
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => ({} as SeminarElement) }) element!: SeminarElement;
  @Action("seminars/addSubmission") addSubmission!: (s: Submission) => void;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  get submissions() {
    return (
      this.seminar?.submissions?.filter(
        x => x.elementId === this.element.id && !x.skipped,
      ) || []
    );
  }

  get skipped() {
    return this.seminar?.submissions?.find(
      x => x.skipped && x.elementId === this.element.id,
    );
  }

  submitting = false;
  inputs: string[] = [];
  async submit() {
    if (this.submitting) throw new Error("Submit loading...");

    if (this.inputs.some(x => !x.length)) {
      this.displaySnackbar(this.$t("seminars.rephrase.error").toString());
      throw new Error("Not all inputs have been filled out");
    }

    this.submitting = true;
    try {
      const data = {
        elementId: this.element.id,
        answers: this.inputs,
        seminarId: this.element.seminarId,
      };
      const submission = (await api.post(
        "/api/Submissions/Rephrase",
        data,
      )) as Submission;
      this.addSubmission(submission);
    } catch (error) {
      this.displaySnackbar(error.description);
    }
    this.submitting = false;
  }

  // todo delete element -> refresh
  // todo go to seminar and load it
  // todo solve issue where site always loads at home page

  created() {
    if (this.element.type !== "rephrase") return;
    this.inputs = new Array(this.element.sentences.length).fill("");
  }
}
