

































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { SeminarElement, Submission } from "@/core/models";
import SubmissionFooter from "./SubmissionFooter.vue";

@Component({ components: { SubmissionFooter } })
export default class RephraseSubmission extends Vue {
  @Prop({ default: () => undefined }) element?: SeminarElement;
  @Prop({ default: () => undefined }) submission?: Submission;

  sentences: string[] = [];
  solutions: string[] = [];
  answers: string[] = [];
  created() {
    if (
      this.element?.type !== "rephrase" ||
      this.submission?.type !== "rephrase"
    )
      return;
    this.sentences = this.element.sentences;
    this.solutions = this.element.answers;
    this.answers = (this.submission as any).answers;
  }
}
