








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { SeminarElement, Submission } from "@/core/models";
import SubmissionFooter from "./SubmissionFooter.vue";

@Component({ components: { SubmissionFooter } })
export default class GapTextSubmission extends Vue {
  @Prop({ default: () => undefined }) element?: SeminarElement;
  @Prop({ default: () => undefined }) submission?: Submission;

  get formattedText() {
    if (this.element?.type !== "gap_text") return "<<wrong_element_type>>";
    const answers =
      this.submission?.type !== "gap_text"
        ? new Array(this.element.answers.length).fill("")
        : this.submission.answers;

    let base = this.element.text.replaceAll(/[\n\r+]/g, "<br/>");
    this.element.answers.forEach(
      (x, i) =>
        (base = base.replace("__", `<b>${x}</b> <i>(${answers[i]})</i>`)),
    );
    return base;
  }
}
