import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider,{staticClass:"mb-4"}),(_vm.submissions.length > 0)?_c('div',_vm._l((_vm.submissions),function(submission,i){return _c('RephraseSubmission',{key:i,staticClass:"mt-4",attrs:{"element":_vm.element,"submission":submission}})}),1):_c('div',_vm._l((_vm.element.sentences),function(sentence,i){return _c('div',{key:i,staticClass:"rephrase-row"},[_c('div',{staticClass:"left",class:{
          first: i === 0,
          last: i === _vm.element.sentences.length - 1,
        }},[_c('div',[_vm._v(_vm._s(sentence))]),(i !== _vm.element.sentences.length - 1)?_c(VDivider,{staticClass:"mt-2 mb-n2"}):_vm._e()],1),_c('div',{staticClass:"middle",class:{
          first: i === 0,
          last: i === _vm.element.sentences.length - 1,
        }},[_c(VTextField,{staticClass:"mx-2 pa-0 other",attrs:{"outlined":"","hide-details":"","placeholder":"Your answer"},model:{value:(_vm.inputs[i]),callback:function ($$v) {_vm.$set(_vm.inputs, i, $$v)},expression:"inputs[i]"}}),(i !== _vm.element.sentences.length - 1)?_c(VDivider,{staticClass:"mt-2 mb-n2"}):_vm._e()],1),_c('div',{staticClass:"flex-1 pa-2"})])}),0),(_vm.skipped)?_c('div',[_c('h4',{staticClass:"mt-4"},[_vm._v(" This task was skipped on "+_vm._s(_vm._f("formatwithtime")(_vm.skipped.createdAt))+". ")])]):_vm._e(),_c('ElementControls',{attrs:{"submit":_vm.submit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }