

































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";
import { Seminar, SeminarBlock, SeminarElement } from "@/core/models";

import WatchVideo from "./WatchVideo.vue";
import RecordVideo from "./RecordVideo.vue";
import GiveFeedback from "./GiveFeedback.vue";
import WatchFeedback from "./WatchFeedback.vue";
import GapText from "./GapText.vue";
import Rephrase from "./Rephrase.vue";
import Categorize from "./Categorize.vue";
import VRTraining from "./VRTraining.vue";
import GoalElement from "./GoalElement.vue";
import ElementControls from "./ElementControls.vue";

import { elementStatus } from "@/core/utils/seminars";

@Component({
  components: {
    WatchVideo,
    RecordVideo,
    GiveFeedback,
    WatchFeedback,
    GapText,
    Rephrase,
    Categorize,
    VRTraining,
    GoalElement,
    ElementControls,
  },
})
export default class SeminarContent extends Vue {
  @Getter("profile/getUserEmail") myEmail!: string;
  @Getter("profile/id") userId!: string;

  @Getter("seminars/block") block?: SeminarBlock;
  @Getter("seminars/selected") seminar?: Seminar;
  @Getter("seminars/blocks") blocks!: SeminarBlock[];
  @Getter("seminars/element") element?: SeminarElement;

  @Action("seminars/next") next!: () => void;
  @Action("seminars/skip") skip!: (el: SeminarElement) => Promise<void>;

  percent = 0;
  calcPercent() {
    if (!this.block) return;
    const complete = this.block.elements.filter(
      x => elementStatus(x, this.seminar) === "completed",
    ).length;
    const val = Math.round((complete / this.block.elements.length) * 100);
    this.percent = isNaN(val) ? 0 : val;
  }

  @Watch("seminar", { immediate: true })
  seminarChanged() {
    this.calcPercent();
  }
  @Watch("block", { immediate: true })
  blockChanged() {
    this.calcPercent();
  }

  openEdit() {
    if (!this.seminar || !this.block || !this.element) return;
    const route = `/seminars/edit/${this.seminar.id}?block=${this.block.id}&element=${this.element.id}`;
    this.$router.push(route);
  }

  get isLast() {
    if (!this.blocks?.length || !this.block || !this.element) return false;
    const blocksWithEls = this.blocks.filter(x => x.elements.length > 0);
    const lastBlock = blocksWithEls[blocksWithEls.length - 1];
    const lastElement = lastBlock.elements[lastBlock.elements.length - 1];
    return (
      lastElement.id === this.element.id && this.element.type === "read_text"
    );
  }
  get shouldShowControls() {
    return (
      this.element?.type === "read_text" ||
      this.element?.type === "watch_video" ||
      this.element?.type === "vr_training"
    );
  }

  skipping = false;
  async skipElement() {
    if (!this.element) return;
    this.skipping = true;
    try {
      await this.skip(this.element);
      this.next();
    } catch (error) {
      console.debug(error);
    }
    this.skipping = false;
  }
}
