

































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { GetSubsRequest, Seminar, SeminarElement } from "@/core/models";
import SubmissionFooter from "../components/SubmissionFooter.vue";

@Component({ components: { SubmissionFooter } })
export default class VRTrainingElement extends Vue {
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => undefined }) element?: SeminarElement;
  @Action("seminars/getSubmissions")
  getSubmissions!: (r: GetSubsRequest) => Promise<void>;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  get submissions() {
    return (
      this.seminar?.submissions?.filter(
        x => x.elementId === this.element?.id && !x.skipped,
      ) || []
    );
  }

  getting = false;
  async getSubs() {
    const seminarId = this.seminar?.id;
    const elementId = this.element?.id;
    if (!seminarId) return this.displaySnackbar("Seminar not found");
    if (!elementId) return this.displaySnackbar("Element not found");

    this.getting = true;
    try {
      this.getSubmissions({ elementId, seminarId });
    } catch (error) {
      console.log(error);
    }
    this.getting = false;
  }
}
