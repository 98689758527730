









import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { SeminarElement } from "@/core/models";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

@Component({ components: { VideoPlayer } })
export default class WatchVideoElement extends Vue {
  @Prop({ default: () => ({} as SeminarElement) }) element!: SeminarElement;
}
