










































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { GetSubsRequest, Goal, Seminar, SeminarElement } from "@/core/models";

import ConditionDisplay from "@/views/goals/components/ConditionDisplay.vue";
import SubmissionFooter from "./SubmissionFooter.vue";

@Component({ components: { SubmissionFooter, ConditionDisplay } })
export default class GoalElement extends Vue {
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => undefined }) element?: SeminarElement;

  @Getter("goals/getAssignedGoals") goals!: Goal[];
  @Action("goals/assignToGoal")
  assignToGoalAction!: (id: number) => Promise<void>;

  @Action("seminars/getSubmissions")
  getSubmissions!: (r: GetSubsRequest) => Promise<void>;
  @Action("seminars/refreshGoalElement")
  refreshGoalElement!: (d: {
    element: SeminarElement;
    makeCall: boolean;
  }) => Promise<boolean>;
  @Action("seminars/setAssignedToGoal")
  setAssignedToGoalAction!: (e: SeminarElement) => Promise<void>;

  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  // tood show skipped submission

  assigning = false;
  async assignToGoal() {
    if (this.element?.type !== "goal") return;

    console.debug("[goals] element", this.element);
    this.assigning = true;
    await this.assignToGoalAction(this.element.goalId);
    await this.setAssignedToGoalAction(this.element);
    this.assigning = false;
  }

  refreshing = false;
  async refresh(makeCall = false) {
    if (!this.element) return;

    this.refreshing = await this.refreshGoalElement({
      element: this.element,
      makeCall,
    });

    if (makeCall)
      window.addEventListener("got-goals", () => this.refresh(false));
  }

  beforeDestroy() {
    window.removeEventListener("got-goals", () => this.refresh(false));
  }
}
